<template>
  <b-card class="shop-categories">
    <div class="row mb-1">
      <div class="col-md-3">
        <label>{{ $t('Status') }}</label>
        <b-form-select
          v-model="filterDates.status"
          :options="$superApp.appConstants.statusList()"
        />
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-3 text-left d-flex flex-wrap align-items-center">
            <button
              class="btn btn-primary mt-2"
              @click="filterData"
            >
              {{ $t('Submit') }}
            </button>
          </div>
          <div class="col-9 text-right d-flex flex-wrap justify-content-end">
            <button
              class="btn btn-primary mt-2"
              @click="chooseFileModal"
            >
              {{ $t('Upload Items') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="itemsRef"
          :button-label="$t('Add Items')"
          @add-new-info="showAddModal"
          :columns="itemsColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()"
          :noSerial="true"
          :chBoxenabled="true"
          :chkBoxAction="{active:true, deactive: true}"
          @enable="enableDisableSelected($event, true)"
          @disable="enableDisableSelected($event, false)"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="uploadItemsModal"
      :no-close-on-backdrop="true"
      :title="$t('Upload Items')"
      size="lg"
      ok-only
      :ok-title="$t('Submit')"
      @hidden="uploadItemsModal = false"
      @ok="shopUploadItems"
    >
      <b-row>
        <b-col
          cols="8"
          md="8"
          class="mt-2"
        >
          <b-form-group :label="$t('Choose Template')">
            <input
              ref="itemsUpload"
              type="file"
              class="form-control"
            >
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
          md="4"
          class="mt-2 d-flex align-items-center justify-content-center"
        >
          <a
            class="btn btn-success"
            :href="fileUrl"
            target="_BLANK"
          > Download Template </a>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="showItemsModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Item' : 'Add Item'"
      size="lg"
      ok-only
      :ok-title="$t('Save Changes')"
      hide-footer
      @hidden="showItemsModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Category')">
            <b-form-select
              v-model="dataInfo.store_category_id"
              :class="{ 'is-invalid': !dataInfo.store_category_id && isDataSubmitted }"
              :options="
                shopCategories.map(x => ({
                  text: x.store_category_name,
                  value: x.id,
                }))
              "
            />
          </b-form-group>
          <b-form-group :label="$t('Brand')">
            <b-form-select
              v-model="dataInfo.brand_id"
              :options="
                shopBrands.map(x => ({
                  text: x.title,
                  value: x.id,
                }))
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Item')">
            <b-form-input
              v-model="dataInfo.item_name"
              :class="{ 'is-invalid': !dataInfo.item_name && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Description')">
            <b-form-input
              v-model="dataInfo.item_description"
              type="text"
            />
            <div class="mt-1">
              <small>(Maximum characters: 100)</small>
              <b-button variant="outline-info" class="ml-2" size="sm" @click="modalState = true">{{ $t('Ask MOM AI') }}</b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Price')">
            <b-form-input
              v-model="dataInfo.item_price"
              :class="{ 'is-invalid': !dataInfo.item_price && isDataSubmitted }"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Cost Price')">
            <b-form-input
              v-model="dataInfo.cost_price"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.status"
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Discount Type')">
            <b-form-select
              v-model="dataInfo.item_discount_type"
              :options="[
                {
                  text: 'PERCENTAGE',
                  value: 'PERCENTAGE',
                },
                {
                  text: 'AMOUNT',
                  value: 'AMOUNT',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Discount')">
            <b-form-input
              v-model="dataInfo.item_discount"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="$t('Quantity')">
                <b-form-input
                  v-model="dataInfo.quantity"
                  type="text"
                  @keypress="$superApp.validations.numberOnly"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="$t('SKU')">
                <b-form-input
                  v-model="dataInfo.sku"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="$t('Low Stock Limit')">
                <b-form-input
                  v-model="dataInfo.low_stock"
                  type="text"
                  @keypress="$superApp.validations.numberOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
          <barcode v-if="!isEmpty(dataInfo.sku)" :value="dataInfo.sku">
              Show this if the rendering fails.
          </barcode>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Units')">
            <b-form-select
              v-model="dataInfo.unit_id"
              :options="
                shopUnits.map(x => ({
                  text: x.name,
                  value: x.id,
                }))
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Unit Value')">
            <b-form-input
              v-model="dataInfo.unit_value"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          :md="isFoodCategory ? 6 : 4"
          class="d-flex align-items-center mb-1"
        >
          <b-form-checkbox
            v-model="dataInfo.is_available"
            switch
            inline
          >
            <span>{{ $t('Available') }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          :md="isFoodCategory ? 6 : 4"
          class="d-flex align-items-center mb-1"
        >
          <b-form-checkbox
            v-model="dataInfo.is_recommended"
            switch
            inline
          >
            <span>{{ $t('Recommended') }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          :md="isFoodCategory ? 6 : 4"
          class="d-flex align-items-center mb-1"
        >
          <b-form-checkbox
            v-model="dataInfo.is_best_seller"
            switch
            inline
            button-variant="success"
          >
            <span>{{ $t('Best Seller') }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="isFoodCategory"
          cols="12"
          :md="isFoodCategory ? 6 : 12"
          class="d-flex align-items-center mb-1"
        >
          <b-form-group label="">
            <b-form-radio-group
              v-model="dataInfo.is_veg"
            >
              <b-form-radio
                value="Pure Veg"
              >
                {{ $t('Veg') }}
              </b-form-radio>
              <b-form-radio
                value="Non Veg"
              >
                {{ $t('Non Veg') }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-2"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :class="{ 'is-invalid': !dataInfo.picture && isDataSubmitted }"
              :default-image="dataInfo.picture"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isDigital"
          cols="12"
          md="6"
          class="mt-2"
        >
          <b-form-group :label="$t('File')">
            <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="fileInputed"
              required
            ></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
      <app-collapse accordion>
        <app-collapse-item :title="`${$t('Addon List')} (${$t('Enter price for the Addons')})`">
          <b-row>
            <div
              v-for="(item, index) of shopAddOns"
              :key="index"
              class="col-md-6"
            >
              <b-form-group>
                <label class="d-flex align-items-center">
                  <input
                    v-model="item.selected"
                    type="checkbox"
                    class="mr-1"
                  >
                  <p class="font-14 mb-0">{{ item.addon_name }}</p>
                </label>
                <b-form-input
                  v-model="item.storeitem.price"
                  placeholder="Enter the price"
                  type="text"
                  @keypress="$superApp.validations.numberOnly"
                />
              </b-form-group>
            </div>
          </b-row>
        </app-collapse-item>
        <app-collapse-item
          class="mt-2"
          :title="`${$t('Variations List')} (${$t('Enter price for the Variations')})`"
        >
          <b-row
            v-for="(item, index) of variationsList"
            :key="index"
          >
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <p>{{ $t('Variation Type') }}: {{ item.title }}</p>
                </div>
              </div>
            </div>
            <div
              v-for="(variant, counter) of item.variation_values"
              :key="counter"
              class="col-md-6"
            >
              <div class="d-flex mb-1">
                <image-input
                  :show-text="false"
                  style="max-width: 130px; max-height: 130px; width: 130px; height: 130px;"
                  :default-image="variant.images"
                  @input-file="addVariationFile($event, variant)"
                />
                <div class="d-f">
                  <label class="d-flex align-items-center">
                    <input
                      v-model="variant.selected"
                      type="checkbox"
                      class="mr-1"
                      @change="variantChanged(variant)"
                    >
                    <p class="font-14 mb-0">{{ variant.title }}</p>
                  </label>
                  <div
                    class="d-flex"
                  >
                    <b-form-input
                      v-model="variant.price"
                      placeholder="Enter the price"
                      type="text"
                      @keypress="$superApp.validations.numberOnly"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </app-collapse-item>
      </app-collapse>
      <modal-footer
        @hidden="showItemsModal = false"
        @ok="saveData"
      />
    </b-modal>
    <SingleTemplateGenerate :modalState="modalState" :templateSlug="templateSlug" @modalClosed="modalState = false" @useThisText="dataInfo.item_description = $event" />
  </b-card>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { useStoreUi } from './useStore'
import ImageInput from '../users/shared/ImageInput.vue'
import SingleTemplateGenerate from '@/components/ai/SingleTemplateGenerate.vue'
import Barcode from 'vue-barcode';
import { isEmpty } from '@/utilities'
import { log } from 'pdfmake/build/pdfmake'
export default {
  components: {
    AppCollapse,
    ModalFooter,
    AppCollapseItem,
    GoodDataTable,
    ImageInput,
    SingleTemplateGenerate,
    Barcode
  },
  data() {
    return {
      sku: 454545,
      isDataSubmitted: false,
      uploadItemsModal: false,
      showItemsModal: false,
      filterDates: {},
      shopUnits: [],
      shopAddOns: [],
      originalAddOns: [],
      shopCategories: [],
      shopBrands: [],
      withPriceOptions: [
        { text: 'With Price', value: 1, disabled: false },
        { text: 'Without Price', value: 0, disabled: false },
      ],
      statusOptions: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      dataInfo: {
        item_name: '',
        picture: null,
        file: null,
        cost_price: '',
        item_description: '',
        item_discount_type: '',
        store_category_id: '',
        brand_id: '',
        quantity: '',
        low_stock: '',
        item_discount: '',
        item_price: '',
        unit: '',
        unit_id: '',
        unit_value: '',
        status: 0,
        id: null,
        store_id: window.SuperApp.getters.userInfo().id,
      },
      variationsList: [],
      originalVariationList: [],
      shopItem: {},
      itemsColumns: [
        {
          label: 'Image',
          field: 'picture',
          align: 'center',
          preventExport: true,
          useHtml: true,
          excelHtml: props => props.picture,
          renderer: props => `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />`,
        },
        {
          label: 'Item',
          field: 'item_name',
        },
        {
          label: 'SKU ID',
          field: 'sku',
        },
        {
          label: 'Category',
          field: 'store_category_id',
          useHtml: true,
          renderer: props => {
            const cat = this.shopCategories.find(i => i.id == props.store_category_id)
            return cat?.store_category_name
          },
        },
        {
          label: 'Price',
          field: 'item_price',
        },
        // {
        //   label: 'Description',
        //   field: 'item_description',
        // },
        {
          label: 'Available',
          field: 'is_available',
          align: 'center',
          excelHtml: props => (props.is_available ? 'Available' : 'Unavailable'),
          showToggle: true,
          action: props => {
            this.$swal({
              title: this.$t('You want to change the availability?'),
              iconHtml: this.$helpers.swalIcon('confirm.png'),
              showCancelButton: true,
              confirmButtonText: this.$t('Yes'),
              cancelButtonText: this.$t('No'),
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
                icon: 'border-0',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.dataInfo = props
                this.dataInfo.file = null
                this.dataInfo.is_available = this.dataInfo.is_available ? 0 : 1
                this.updateItemsForShop(props)
              } else {
                this.$refs.itemsRef.resetToOriginal()
              }
            })
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
          rField: 'status_text',
          useResolver: true,
          renderer: props => (props.status === 0 ? 'Inactive' : 'Active'),
          action: props => {
            this.$swal({
              title: this.$t('You want to change the status?'),
              iconHtml: this.$helpers.swalIcon('confirm.png'),
              showCancelButton: true,
              confirmButtonText: this.$t('Yes'),
              cancelButtonText: this.$t('No'),
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
                icon: 'border-0',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.changeItemsStatus(props)
              }
            })
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editItems(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.$swal({
                  title: this.$t('You want to change the status?'),
                  iconHtml: this.$helpers.swalIcon('confirm.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.changeItemsStatus(props)
                  }
                })
              },
            },
          ],
        },
      ],
      templateSlug: 'product-description',
      modalState: false
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isDigital: {
      get(){
        return this.storeInfo.product_type == 2;
      }
    },
    isFoodCategory() {
      return this.storeInfo.store_type && this.storeInfo.store_type.category === 'FOOD'
    },
    fileUrl() {
      return `${jwtDefaultConfig.adminEndpoint}/assets/uploads/items_template.xlsx`
    },
  },
  mounted() {
    this.getShopUnit()
    this.getShopAddons()
    this.categoryList()
    this.brandList()
    this.getVariationsList()
  },
  methods: {
    isEmpty,
    fileInputed(file){
      this.dataInfo.file = file
    },
    addVariationFile($event, variation) {
      // eslint-disable-next-line no-param-reassign
      variation.images = null
      if ($event) {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        variation.images = $event
      }
    },
    variantChanged(props) {
      if (props && props.selected && !props.price && this.dataInfo.item_price) {
        // eslint-disable-next-line no-param-reassign
        props.price = parseFloat(this.dataInfo.item_price)
      }
    },
    setNewVaraitions() {
      this.variationsList = window.SuperApp.actions.cloneDeep(
        this.originalVariationList.map(x => ({
          ...x,
          variation_values: x.variation_values.map(y => ({
            title: y,
            price: 0,
            selected: false,
            variation_id: x.id,
          })),
        })),
      )
    },
    getVariationsList() {
      const { getVariationsList } = useStoreUi()

      getVariationsList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.originalVariationList = window.SuperApp.actions.cloneDeep(data.responseData)
            this.setNewVaraitions()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFile(payload) {
      if (this.dataInfo) this.dataInfo.picture = payload
    },
    chooseFileModal() {
      this.uploadItemsModal = true
      this.$nextTick(() => {
        if (this.$refs.itemsUpload) {
          this.$refs.itemsUpload.value = ''
        }
      })
    },
    filterData() {
      const payload = []
      if (this.filterDates.status) payload.push(`status=${this.filterDates.status}`)
      this.$refs.itemsRef.loadItems(payload.join('&'))
    },
    shopUploadItems() {
      if (!this.$refs.itemsUpload) {
        return
      }
      if (!this.$refs.itemsUpload.files[0]) {
        return
      }
      const { shopUploadItems } = useStoreUi()
      const formData = new FormData()
      formData.append('items', this.$refs.itemsUpload.files[0])
      showLoader()
      shopUploadItems(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.uploadItemsModal = false
            if (this.$refs.itemsUpload) {
              this.$refs.itemsUpload.value = ''
            }
            this.$refs.itemsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.uploadItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.uploadItemsModal = true
          showErrorNotification(this, error)
        })
    },
    changeItemsStatus(props) {
      const payload = window.SuperApp.actions.cloneDeep(props)
      showLoader()
      const { updateItemsStatus } = useStoreUi()

      updateItemsStatus({
        query: payload.id,
        status: payload.status,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.itemsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getFormData() {
      const formData = new FormData()
      this.dataInfo.store_id = window.SuperApp.getters.userInfo().id
      this.dataInfo.unit = this.dataInfo.unit_id
      const booleanKeys = ['is_best_seller', 'is_recommended', 'is_available']

      Object.keys(this.dataInfo).forEach(x => {
        if (booleanKeys.includes(x)) {
          formData.append(x, Number(this.dataInfo[x]))
        } else if (x === 'status') {
          formData.append(x, Number(this.dataInfo[x]))
        } else if (this.dataInfo[x]) {
          if (x === 'picture' && window.SuperApp.helper.withBaseUrl(this.dataInfo.picture)) {
            formData.append(x, this.dataInfo[x])
          }else if (x === 'file') {
            if(!isEmpty(this.dataInfo.file)){
              formData.append(x, this.dataInfo.file)
            }
          } else if (x !== 'picture') {
            formData.append(x, this.dataInfo[x])
          }
        }
      })
      this.shopAddOns
        .filter(x => x.selected)
        .forEach(item => {
          formData.append('addon[]', item.id)
          formData.append(`addon_price[${item.id}]`, item.storeitem.price)
        })
      this.variationsList
        .flatMap(x => x.variation_values)
        .filter(x => x.selected)
        .forEach(item => {
          const isExist = this.variationsList.find(y => y.id === item.variation_id)
          formData.append(`variation_ids[${item.variation_id}][${item.title}][price]`, item.price ? item.price : 0)
          if (item.images) {
            const index = isExist.variation_values.findIndex(z => z.title === item.title)
            if (index >= 0) {
              formData.append(`variation_ids[${item.variation_id}][${item.title}][images][${index}]`, item.images)
            }
          }
        })
      return formData
    },
    updateItemsForShop() {
      const { updateShopItems } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      formData.append('_method', 'PATCH')
      updateShopItems({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.itemsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
    enableDisableSelected(data, type){
      this.$swal({
        title: this.$t('You want to change the status?'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          showLoader()
          const { updateShopAvailability } = useStoreUi()
          await updateShopAvailability({
            ids: JSON.stringify(data.selectedRows.map(i => i.id)),
            is_available: type ? 1 : 0,
          }).then(({ data }) => {
            if (data.statusCode === '200') {
              this.$refs.itemsRef.loadItems()
              showSuccessNotification(this, data.message)
            } else {
              this.showItemsModal = true
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            hideLoader()
            showErrorNotification(this, error)
          })
        } else {
          this.$refs.serviceGrid.resetToOriginal()
        }
      })
    },
    editItems(props) {
      showLoader()
      const { getShopItems } = useStoreUi()
      this.getShopAddons(`/${props.id}`)
      getShopItems(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopItem = data.responseData
            const booleanKeys = ['is_best_seller', 'is_recommended', 'is_available']
            booleanKeys.forEach(item => {
              this.shopItem[item] = Boolean(this.shopItem[item])
            })
            Object.keys(this.shopItem).forEach(x => {
              this.dataInfo[x] = this.shopItem[x]
            })
            this.dataInfo.picture = window.SuperApp.helper.image(this.dataInfo.picture)
            this.setNewVaraitions()
            this.variationsList.forEach(item => {
              item.variation_values.forEach((variation, findIndex) => {
                const isExist = data.responseData.variation_prices.find(x => x.variation_option_id === variation.title && x.variation_id === variation.variation_id)
                if (isExist) {
                  // eslint-disable-next-line no-param-reassign
                  variation.selected = true
                  // eslint-disable-next-line no-param-reassign
                  variation.price = parseFloat(isExist.option_price)
                  if (Array.isArray(isExist.images)) {
                    // eslint-disable-next-line no-param-reassign
                    variation.images = isExist.images[findIndex]
                  }
                }
              })
            })
            this.showItemsModal = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showItemsModal = false
        this.isDataSubmitted = false
        if (this.dataInfo.id) {
          this.updateItemsForShop()
        } else {
          this.addItemsForShop()
        }
      })
    },
    addItemsForShop() {
      const { addShopItems } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      addShopItems(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.itemsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
    getShopUnit() {
      const { getShopUnits } = useStoreUi()

      getShopUnits()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopUnits = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getShopAddons(itemId = '') {
      const { shopAddOnsList } = useStoreUi()

      shopAddOnsList(`${this.storeInfo.id}${itemId}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopAddOns = data.responseData
            this.shopAddOns.forEach(item => {
              if (item.storeitem) {
                // eslint-disable-next-line no-param-reassign
                item.selected = true
              } else {
                // eslint-disable-next-line no-param-reassign
                item.selected = false
                // eslint-disable-next-line no-param-reassign
                item.storeitem = {
                  price: 0,
                  isCustom: true,
                }
              }
            })
            this.originalAddOns = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    categoryList() {
      const { shopCategoryList } = useStoreUi()

      shopCategoryList(this.storeInfo.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopCategories = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    brandList() {
      const { shopBrandList } = useStoreUi()
      shopBrandList(this.storeInfo.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopBrands = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getApiEndpoint() {
      return `/shop/itemsindex/${this.storeInfo.id}`
    },
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.setNewVaraitions()
      this.dataInfo.status = 1
      this.showItemsModal = true
      this.isDataSubmitted = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.items-with-price {
  .custom-control-inline {
    margin-top: 0 !important;
  }
}
</style>
