<template>
  <div class="row">
    <div class="col-12">
        <b-modal v-model="openModal" size="lg" centered :title="$t('Generate Content')" hide-footer>
            <div class="row">
                <b-form @submit.prevent="_generateTemplate" class="w-100">
                    <div class="col-12" v-for="(field, fk) in fields" :key="`field_${fk}`">
                        <b-form-group class="" :label="field.label">
                            <template v-if="field.type == 'text'">
                                <b-input :required="true" :name="field.name" :disabled="loading" v-model="form[field.name]" :placeholder="field.placeholder" />
                            </template>
                            <template v-else-if="field.type == 'textarea'">
                                <b-textarea
                                    :name="field.name" 
                                    :disabled="loading" 
                                    v-model="form[field.name]" 
                                    :placeholder="field.placeholder"
                                    :required="true"
                                />
                            </template>
                        </b-form-group>
                    </div>
                    <div class="col-12">
                        <b-button class="mb-2" type="submit" :variant="loading ? 'secondary' : 'primary'" :disabled="loading || isEmpty(template)">
                            <template v-if="loading">
                                {{ $t('Generating') }}...
                            </template>
                            <template v-else>
                                {{ $t('Generate') }}
                            </template>
                        </b-button>
                    </div>
                </b-form>
            </div>
            <div class="row" v-if="!isEmpty(copyText)">
                <div class="col-12 d-flex justify-content-end mb-1">
                    <b-button variant="outline-info" @click="useThisText" class="mr-2">{{ $t('Use this Text') }}</b-button>
                    <b-img
                        :title="'Copy to Clipboard'"
                        @click="copyToClipBoard(copyText)"
                        width="24"
                        height="24"
                        :src="require('@/assets/images/app-icons/icons8-copy-48.png')"
                    />
                </div>
                <div class="col-12">
                    <ckeditor
                        v-model="copyText"
                        :editor="editor"
                    />
                </div>
            </div>
        </b-modal>
    </div>
  </div>
</template>

<script>
import { showErrorNotification, showLoader, hideLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { isEmpty } from '@/utilities';
import { mapActions, mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


export default {
    props: {
        modalState: {
            type: Boolean,
            default: false,
            required: true,
        },
        templateSlug: {
            type: String,
            default: () => "",
            required: true,
        },
    },
    data: () => ({
        openModal: false,
        loading: false,
        copyText: null,
        form: {},
        editor: ClassicEditor,
    }),
    computed: {
        ...mapGetters('aiTemplate', [
            'aiTemplateBySlug'
        ]),
        template: {
            get(){
                return this.aiTemplateBySlug(this.templateSlug)
            }
        },
        fields: {
            get(){
                if(isEmpty(this.template)) return []
                const { fields } = this.template
                if(isEmpty(fields)) return []
                return eval(fields)
            }
        },
    },
    methods: {
        isEmpty,
        ...mapActions('aiTemplate', [
            'getAiTemplateBySlug',
            'generateTemplate',
        ]),
        async getTemplateBySlug(){
            if(isEmpty(this.templateSlug)) return false;
            const template = this.aiTemplateBySlug(this.templateSlug);
            if(!isEmpty(template)) return false;
            this.loading = true;
            await this.getAiTemplateBySlug(this.templateSlug)
            this.loading = false;
        },
        async _generateTemplate(){
            this.loading = true
            showLoader()
            const payLoad = {
                ...this.form,
                lang: 'English',
                tone: 'Professional',
                template_id: this.template.id,
                char_limit: 100,
            }
            this.generateTemplate(payLoad).then(r => {
                const {_statusCode, responseData, message} = r
                if(_statusCode === 203){
                    this.$swal({
                        title: this.$t(message),
                        iconHtml: this.$helpers.swalIcon('alert.png'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('Upgrade'),
                        cancelButtonText: this.$t('Later'),
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger ml-1',
                            icon: 'border-0',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result?.isConfirmed){
                            this.$router.push({
                                name: `${this.route_type}-profile`, query: {
                                    tab: 'subscription'
                                }
                            })
                        }
                    })
                }else{
                    if (_statusCode === 200) {
                        const {template} = responseData
                        this.copyText = template
                    }else if (_statusCode === 201) {
                        showErrorNotification(this, message)
                    }else {
                        showErrorNotification(this, "Something went wrong!")
                    }
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                showErrorNotification(this, "Something went wrong!")
                this.loading = false
                hideLoader()
            });
        },
        copyToClipBoard(data) {
            const textarea = document.createElement('textarea');
            textarea.textContent = data;
            document.body.appendChild(textarea);

            const selection = document.getSelection();
            const range = document.createRange();
            range.selectNode(textarea);
            selection.removeAllRanges();
            selection.addRange(range);

            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            document.body.removeChild(textarea);
            selection.removeAllRanges();
        },
        useThisText(){
            this.$emit('useThisText', this.copyText)
            this.openModal = false;
        }
    },
    watch: {
        modalState: {
            handler(nv){
                if(nv === true){
                    this.openModal = true;
                }
            },
            deep: true,
            immediate: true
        },
        openModal: {
            handler(nv){
                if(nv === false){
                    this.$emit('modalClosed')
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted(){
        this.getTemplateBySlug()
    },
}
</script>